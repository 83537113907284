import React from "react";
import { Snackbar, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

export default function AlertSnackbar({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters
}) {
  return (
    <Snackbar autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} {...SnackbarProps}>
      <Alert
        elevation={8} 
        severity={customParameters?.type}
        action={
          action != null && (
            <Button color="inherit" size="small" {...ButtonProps}>
              {action}
            </Button>
          )
        }
      >
        <AlertTitle>
          {customParameters?.title}
        </AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
}
