import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './common';

const importLayout = layout =>
  lazy(() =>
    import(`layouts/${layout}`),
  );

const importView = view =>
  lazy(() =>
    import(`views/${view}`),
  );

const Routes = () => {
  return (
    <Suspense fallback="">
      <Switch>
        <Route
          path="/admin/overview"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/admin/overview.php',
            );
            return null;
          }}
        />
        <Route
          path="/admin/charities"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/admin/view_charities.php',
            );
            return null;
          }}
        />
        <Route
          path="/admin/keywords"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/admin/view_keywords.php',
            );
            return null;
          }}
        />
        <Route
          path="/admin/compliance"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/admin/view_compliance.php',
            );
            return null;
          }}
        />
        <Route
          path="/admin/finance"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/admin/finance_new.php',
            );
            return null;
          }}
        />
        <Route
          path="/admin/technical"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/admin/technical.php',
            );
            return null;
          }}
        />
        <RouteWithLayout
          component={importView('Admin')}
          exact
          layout={importLayout('Main')}
          path="/admin/:pageId?"
        />
        <Redirect exact from="/" to="/sign-in" />
        <RouteWithLayout
          component={importView('SigninCover')}
          exact
          layout={importLayout('Minimal')}
          path="/sign-in"
        />
        <RouteWithLayout
          component={importView('NotFoundCover')}
          exact
          layout={importLayout('Minimal')}
          path="/not-found"
        />
        <Route
          path="/contact-us"
          component={() => {
            window.location.replace('https://instagiv.com/contact/');
            return null;
          }}
        />
        <RouteWithLayout
          component={importView('Dashboard')}
          exact
          layout={importLayout('Main')}
          path="/dashboard"
        />
        <Route
          path="/old-dashboard"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/dashboard.php',
            );
            return null;
          }}
        />
        <Route
          path="/password-reset"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/resetPass.php',
            );
            return null;
          }}
        />
        <Route
          path="/overview"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/overview.php',
            );
            return null;
          }}
        />
        <Route
          path="/inbound-sms"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=SMS',
            );
            return null;
          }}
        />
        <Route
          path="/response-sms"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=RESP&',
            );
            return null;
          }}
        />
        <Route
          path="/fundraisers"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=FUNDRAISERS&=',
            );
            return null;
          }}
        />
        <Route
          path="/reg-donors"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=SUBS&=',
            );
            return null;
          }}
        />
        <Route
          path="/opt-in-outs"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=CONSENT_LOG&=',
            );
            return null;
          }}
        />
        <Route
          path="/sent"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=SENT',
            );
            return null;
          }}
        />
        <Route
          path="/scheduled"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=SCHED',
            );
            return null;
          }}
        />
        <Route
          path="/drafts"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=DRAFT',
            );
            return null;
          }}
        />
        <Route
          path="/recipients"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/recipients.php',
            );
            return null;
          }}
        />
        <Route
          path="/consent"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=CONSENT',
            );
            return null;
          }}
        />
        <Route
          path="/gift-aid/declarations"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=GIFT&t2=DEC',
            );
            return null;
          }}
        />
        <Route
          path="/gift-aid/donations"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=GIFT&t2=DON',
            );
            return null;
          }}
        />
        <Route
          path="/gift-aid/download"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=GIFT&t2=DL',
            );
            return null;
          }}
        />
        <Route
          path="/my-account"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/my-account.php',
            );
            return null;
          }}
        />
        <Route
          path="/faqs"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/uploaded_files/Platform-FaQs.doc',
            );
            return null;
          }}
        />
        <Route
          path="/action-log"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/view_data.php?t=LOGS',
            );
            return null;
          }}
        />
        <Route
          path="/compliance"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-area/compliance.php',
            );
            return null;
          }}
        />
        <Route
          path="/register"
          component={() => {
            window.location.replace(process.env.REACT_APP_REGISTER_URL);
            return null;
          }}
        />
        <Route
          path="/logout"
          component={() => {
            window.location.replace(
              process.env.REACT_APP_IG2V2_URL + '/charity-user/logout',
            );
            return null;
          }}
        />
        <Redirect to="/not-found" status="404" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
